import React, { useState, useLayoutEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Collapse, Navbar, Nav, NavItem } from "reactstrap"
import Logo from "../img/logo"
import ALogoFooter from "../img/logo-footer"
import { useSpring, animated } from "react-spring"

const ANavigation = ({ children, navlocation }) => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        generalSettings {
          url
        }
        menu(id: "dGVybToy") {
          menuItems {
            nodes {
              id
              url
              label
            }
          }
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const { url } = data.wpgraphql.generalSettings
  const items = data.wpgraphql.menu.menuItems.nodes.map(item => ({
    ...item,
    url: item.url.replace(url, ""),
  }))

  function useLockBodyScroll() {
    useLayoutEffect(() => {
      const originalStyle = window.getComputedStyle(document.body).overflow
      document.body.style.overflow = "hidden"
      return () => (document.body.style.overflow = originalStyle)
    }, []) // Empty array ensures effect is only run on mount and unmount
  }

  function Navlock({ path, navlocation }) {
    // Call hook to lock body scroll
    useLockBodyScroll()

    return <></>
  }

  // Hamburger Animation
  const openedTransformationConfig = {
    top: "translate(2, 7) rotate(0)",
    center: "translate(2, 19) rotate(0)",
    bottom: "translate(2, 31) rotate(0)",
    color: "#17AC53", // Add color
  }
  const closedTransformationConfig = {
    top: "translate(5, 32) rotate(-45)",
    center: "translate(9, 4) rotate(45)",
    bottom: "translate(5, 32) rotate(-45)",
    color: "#17AC53", // Add color
  }
  const { top, center, bottom, color } = useSpring({
    to: isOpen ? closedTransformationConfig : openedTransformationConfig,
  })

  return (
    <Container fluid={true} className="container__nav">
      <Navbar light expand="md" id="navbar" fixed="top">
        <Link
          title="AHDC - Affordable Housing Development Corporation"
          to="/"
          className="navbar-brand"
        >
          <Logo />
        </Link>

        <button
          aria-label="Toggle navigation"
          type="button"
          className="navbar-toggler"
        >
          <animated.svg
            onClick={toggle}
            className="menu-button"
            width="34"
            height="34"
            viewBox="0 0 44 44"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <animated.rect width="40" height="5" rx="0" transform={top} />
            <animated.rect width="40" height="5" rx="0" transform={center} />
            <animated.rect width="40" height="5" rx="0" transform={bottom} />
          </animated.svg>
        </button>

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <div className="mobile-logo">
              <ALogoFooter />
            </div>
            {isOpen && <Navlock />}
            {items.map(item => (
              <NavItem key={item.id}>
                {item.url === navlocation && true ? (
                  <Link
                    className="nav-link text-secondary"
                    partiallyActive={true}
                    activeClassName="active"
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    className="nav-link text-secondary"
                    activeClassName="active"
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                )}
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    </Container>
  )
}

export default ANavigation
